import {MegaAppAxiosInstance} from "./axios.instance";
import {PaginatedResponse} from "./items-api";


export type ReferenceType = {
    id: number,
    name: string,
    created_at: Date,
    updated_at: Date
    items?: ReferenceType[]
}

export enum References {
    categories = 'categories',
    types = 'types',
    subtypes = 'subtypes',
    rarities = 'rarities',
}

export const ReferenceApi = {

    async getAll(type: References): Promise<ReferenceType[]> {
        try {
            return MegaAppAxiosInstance.get<ReferenceType[]>(`/reference/${type}`, {}).then((r) => r.data)
        } catch (error) {
            console.log(error)
            throw new Error('Ошибка при выполнении запроса');
        }
    },


    getById(id: number, type: References): Promise<ReferenceType> {
        return MegaAppAxiosInstance.get(`/reference/${type}/${id}`).then((r) => r.data)
    },

    create(type: References, name: string, id = 0) {
        return MegaAppAxiosInstance.post(`/reference/${type}/${id}`, {name}).then((r) => r.data)
    }

}


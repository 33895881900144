import * as React from 'react';
import {FC} from 'react';
import Dialog from '@mui/material/Dialog';
import List from '@mui/material/List';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import {TransitionProps} from '@mui/material/transitions';
import {Item} from "../../api/items-api";
import styles from "../items/items.module.css";
import {ItemsPage} from "../items/Items";

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

type PropsType = {
    open: boolean
    setOpen: (open: boolean) => void
    onSelect: (item: Item) => void
}
export const FindItem: FC<PropsType> = ({open, setOpen, onSelect}) => {
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <React.Fragment>
            <Dialog
                fullScreen
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}
            >
                <AppBar sx={{position: 'relative'}}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon/>
                        </IconButton>
                        <Typography sx={{ml: 2, flex: 1}} variant="h6" component="div">
                            Выберите предмет
                        </Typography>
                    </Toolbar>
                </AppBar>
                <List className={styles.listWrapper}>
                    <ItemsPage rowClick={onSelect}/>
                </List>
            </Dialog>
        </React.Fragment>
    );
}

import React, {FC, MouseEventHandler, useEffect, useState} from 'react';
import styles from './square.module.css';
import {FindItem} from "./FindItems";
import {Item} from "../../api/items-api";
import {CraftResult} from "../../api/recipes-api";
import Typography from "@mui/material/Typography";
import {Button} from "@mui/material";
import {HtmlTooltip} from "./Tooltip";

type PropsType = {
    count: number
    setItems: (value: number[]) => void
    setPreview: (value: any) => void
    previews: any
    items: number[]
    results?: CraftResult[]
    handleDeleteResultItem?: (itemId: number) => void
    items_json?: Item[]
}
export const Square: FC<PropsType> = ({count, setItems, setPreview, previews, items, results, handleDeleteResultItem, items_json}) => {
    const [numbers, setNumbers] = useState(Array(count).fill(0));
    const [open, setOpen] = useState(false);
    const [index, setIndex] = useState(0);

    useEffect(() => {
        if (items.length) {
            setNumbers(items)
        } else {
            setNumbers(Array(count).fill(0))
        }
    }, [count, items]);

    const handleCellClick = (index: number) => {
        setIndex(index)
        setOpen(true)
    };

    const handleClear = (index: number) => {
        const newNumbers = [...numbers];
        newNumbers[index] = 0;
        setNumbers(newNumbers);
        setItems(newNumbers)
        const newPreviews = {...previews}
        delete newPreviews[index]
        setPreview(newPreviews)
    };

    const handleOnSelect = (item: Item) => {
        const newNumbers = [...numbers];
        newNumbers[index] = item.id;
        setNumbers(newNumbers);
        setItems(newNumbers)
        setOpen(false)
        const newPreviews = {...previews}
        if (item.id) {
            newPreviews[index] = item.preview
            setPreview(newPreviews)
        }

    }

    return (
        <div className={styles.squareContainer}>
            {numbers.map((number, index) => {
                const item = results?.find((r) => r.itemId === number)
                const itemJson = items_json?.find((i) => i.id === number)
                return <div key={index} className={styles.squareCell} onClick={() => handleCellClick(index)}>
                    {itemJson?.id && <div className={styles.item}>{itemJson?.id}</div>}
                    {previews[index] && <div className={styles.deleteIcon} onClick={(event) => {
                        event.stopPropagation()
                        handleClear(index)
                        handleDeleteResultItem && item?.itemId && handleDeleteResultItem(item.itemId)
                    }}>X</div>}
                    <div>
                        <HtmlTooltip
                            title={
                                <div onClick={(event) => {
                                    event.stopPropagation()
                                }}>
                                    <div><em>id: <b>{itemJson?.id}</b></em></div>
                                    <div><em>ModelId: <b>{itemJson?.model_id}</b></em></div>
                                    <div><em>Category: <b>{itemJson?.category?.name}</b></em></div>
                                    <div><em>Type: <b>{itemJson?.type?.name}</b></em></div>
                                    <div><em>Sub type: <b>{itemJson?.sub_type?.name}</b></em></div>
                                    <div><em>Rarity: <b>{itemJson?.rarity?.name}</b></em></div>
                                </div>
                            }
                        >

                        <img alt={''} width={110}
                             src={previews[index]}/>
                        </HtmlTooltip>
                        <div className={styles.probability}>{item?.probability && <div>{item.probability} ({(item.probability * 100).toFixed(1)+`%`})</div>}</div>
                    </div>
                </div>
            })}
            <FindItem open={open} setOpen={setOpen} onSelect={handleOnSelect}/>
        </div>
    );
};

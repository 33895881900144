import React, {useState} from "react";
import {useQuery} from "react-query";
import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
import {ItemsPage} from "./pages/items/Items";
import {Container} from "@mui/material";
import {Sidebar} from "./components/sidebar/Sidebar";
import {Header} from "./components/header/Header";
import {LoginCard} from "./pages/LoginPage";
import styles from './app.module.css'
import {CredentialResponse} from "@react-oauth/google";
import {GoogleAuthApi} from "./api/google-auth-api";
import {useAuthContext} from "./auth_context";
import {AddItemPopUp} from "./pages/items/AddItem";
import {Recipes} from "./pages/recipes/Recipes";
import {AddRecipes} from "./pages/recipes/AddRecipes";
import {ReferenceApi, References} from "./api/reference-api";
import {ReferencesPage} from "./pages/references/References";
import {ImportItems} from "./pages/items/ImportItems";
import {ItemsApi} from "./api/items-api";
import {RecipesApi} from "./api/recipes-api";

function App() {
    const {
        data: categories
    } = useQuery(['categories'], () => ReferenceApi.getAll(References.categories),);
    const {
        data: types
    } = useQuery(['types'], () => ReferenceApi.getAll(References.types),);
    const {
        data: subtypes
    } = useQuery(['subtypes'], () => ReferenceApi.getAll(References.subtypes),);
    const {
        data: rarities
    } = useQuery(['rarities'], () => ReferenceApi.getAll(References.rarities),);

    const [isOpenMenu, setIsOpenMenu] = useState(false)
    const {isLogin, loading, handleLogin, user} = useAuthContext();
    const handleOnSuccessLogin = async (response: CredentialResponse) => {
        if (response.credential) {
            const res = await GoogleAuthApi.login(response.credential)
            localStorage.setItem('access-token', res.access_token)
            handleLogin(true)
        }
    }

    const onImportItems = async (url: string) => {
        return await ItemsApi.import(url)
    }
    const onImportRecipes = async (url: string) => {
        return await RecipesApi.import(url)
    }
    return (
        <BrowserRouter>
            {isLogin ? <>
                <Header setIsOpenMenu={setIsOpenMenu}/>
                <Container maxWidth={'xl'}>
                    <Sidebar open={isOpenMenu} setIsOpenMenu={setIsOpenMenu}/>
                    <Routes>
                        <Route path="/items" element={<ItemsPage/>}>
                            <Route path=":itemId" element={<ItemsPage/>}/>
                            <Route path="add" element={<AddItemPopUp open={true}/>}/>
                            <Route path="import"
                                   element={<ImportItems title={'Items'} open={true} onSuccess={onImportItems}
                                                         navigateUrl={'/items'}/>}/>
                        </Route>
                        <Route path={'/recipes'} element={<Recipes/>}>
                            <Route path="/recipes/import"
                                   element={<ImportItems title={'Recipes'} open={true} onSuccess={onImportRecipes}
                                                         navigateUrl={'/recipes'}/>}/>
                        </Route>
                        <Route path="/recipes/add" element={<AddRecipes/>}/>
                        <Route path="/recipes/add/:recipesId" element={<AddRecipes/>}/>
                        <Route path="/references" element={<ReferencesPage/>}/>
                    </Routes>
                </Container>
            </> : <div className={styles.login}><LoginCard onSuccess={handleOnSuccessLogin}/></div>
            }
        </BrowserRouter>
    );
}

export default App;

import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import {
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    InputLabel,
    MenuItem,
    Select, TableCell,
    TextField
} from "@mui/material";
import {Item, ItemsApi, ItemSubTypeEnum, ItemTypeEnum} from "../../api/items-api";
import styles from './items.module.css'
import {JsonEditor} from "../../components/JsonEditor";
import {ReferenceApi, References, ReferenceType} from "../../api/reference-api";
import {useQuery, useQueryClient} from "react-query";
import {SelectClassifier} from "./form/SelectClasificator";

interface EditPopupProps {
    open: boolean // Добавлено для сохранения изменений
}

export const AddItemPopUp: React.FC<EditPopupProps> = ({open}) => {
    const [categoryId, setCategoryId] = useState(0)
    const [typeId, setTypeId] = useState(0)
    const [subtypeId, setSubtypeId] = useState(0)
    const [rarityId, setRarityId] = useState(0)
    const [isStack, setIsStack] = useState(false)
    const [modelId, setModelId] = useState<string | null>(null)
    const [name, setName] = useState<string | null>(null)
    const [description, setDescription] = useState<string | null>(null)
    const [loading, setLoading] = useState(false); // или любое другое значение по умолчанию

    const queryClient = useQueryClient()
    const categories = queryClient.getQueryData<ReferenceType[]>('categories')
    const rarities = queryClient.getQueryData<ReferenceType[]>('rarities')
    const {
        data: category
    } = useQuery(['category', categoryId], () => ReferenceApi.getById(categoryId, References.categories), {cacheTime: 0});

    const {
        data: type
    } = useQuery(['type', typeId], () => ReferenceApi.getById(typeId, References.types), {cacheTime: 0});


    const navigate = useNavigate()
    const handleOnClose = () => {
        navigate('/items')
    }

    const handleSetCategoryId = (id: number) => {
        setTypeId(0)
        setSubtypeId(0)
        setCategoryId(id)
    }

    const handleSetTypeId = (id: number) => {
        setSubtypeId(0)
        setTypeId(id)
    }
    const handleSaveItem = async (item: Item) => {
        if (modelId && type) {
            setLoading(true)
            await ItemsApi.create({
                model_id: modelId,
                subtype_id: subtypeId || null,
                type_id: typeId || null,
                category_id: categoryId || null,
                rarity_id: rarityId || null,
                properties: item.properties,
                is_stack: isStack,
                description,
                name
            })
            setLoading(false)
            navigate('/items')
        } else {

        }
    }

    const selectsData = [
        {name: "categories", items: categories || [], value: categoryId, setValue: handleSetCategoryId, disable: false},
        {name: "types", items: category?.items || [], value: typeId, setValue: handleSetTypeId, disable: !categoryId},
        {name: "subtypes", items: type?.items || [], value: subtypeId, setValue: setSubtypeId, disable: !typeId},
        {name: "rarities", items: rarities || [], value: rarityId, setValue: setRarityId, disable: false},
    ]
    return (
        <Dialog open={open} onClose={handleOnClose}>
            <div className={styles.AddItem}>
                <DialogTitle>Создать Item</DialogTitle>
                <DialogContent className={styles.contentWrapper}>
                    <div>
                        <FormControl fullWidth className={styles.formItem}>
                            <TextField placeholder={'X42-D8BC2B956E67F96D'} label={'Model ID'} value={modelId}
                                       size={'small'} InputLabelProps={{shrink: true}}
                                       onChange={(event) => setModelId(event.target.value as string)}/>
                        </FormControl>
                        <FormControl fullWidth className={styles.formItem}>
                            <TextField  label={'Name'} value={name}
                                       size={'small'} InputLabelProps={{shrink: true}}
                                       onChange={(event) => setName(event.target.value as string)}/>
                        </FormControl>
                        <FormControl fullWidth className={styles.formItem}>
                            <TextField placeholder={''} label={'Description'} multiline={true} rows={4} value={description} InputLabelProps={{shrink: true}}
                                       onChange={(event) => setDescription(event.target.value as string)}/>
                        </FormControl>
                    </div>
                    <div>
                        {selectsData.map((s) => {
                            return <FormControl fullWidth className={styles.formItem}>
                                <SelectClassifier items={s.items} value={s.value} setValue={s.setValue} name={s.name}
                                                  disable={s.disable}/>
                            </FormControl>
                        })}
                    </div>
                    <div className={styles.checkbox}>
                        <Checkbox id={'isStack'} checked={isStack} onChange={(event, checked) => {
                            setIsStack(checked)
                        }}/>
                        <InputLabel id={'isStack'}>isStack</InputLabel>
                    </div>
                </DialogContent>
                <DialogContent>
                    <JsonEditor loading={loading} item={{properties: {}, model_id: modelId}} onSave={handleSaveItem}
                                onClose={handleOnClose}/>
                </DialogContent>


                <DialogActions></DialogActions>
            </div>
        </Dialog>
    );
};

import React, { createContext, useContext, useState, useEffect } from "react"
import {GoogleAuthApi, RolesEnum, UserEntity, UserStatusEnum} from "./api/google-auth-api";
// import dotenv from "dotenv";
// dotenv.config();


type Props = {
    children: React.ReactNode
}
type Context = {
    loading: boolean,
    isLogin: boolean,
    user: null | UserEntity,
    handleLogin: (isLogin: boolean)=>void,
    handleLogout: ()=>void,
}

// Just find-replace "AuthContext" with whatever context name you like. (ie. DankContext)
const AuthContext = createContext<Context | null>(null)

export const AuthContextProvider = ({ children }: Props) => {
    const [loading, setLoading] = useState(true)
    const [isLogin, setIsLogin] = useState(false)
    const [user, setUser] = useState<null | UserEntity>(null)

    const authMe = async () => {
        const user = await GoogleAuthApi.me()
        setUser(user)
        setIsLogin(true)
    }
    useEffect(() => {
        if(process.env.NODE_ENV === 'development'){
            setUser({
                email: 'bazunc@gmail.com',
                roles: [RolesEnum.Admin],
                status: UserStatusEnum.Active
            });
            setIsLogin(true);
            return
        }
        authMe().then(() => {
            setLoading(false)
        }).catch(() => {
            setLoading(false)
        })
    }, [])
    const handleLogin = (isLogin: boolean) => {
        if (isLogin) {
            authMe().then(() => {
                setIsLogin(isLogin)
                setLoading(false)
            }).catch(() => {
                setLoading(false)
            })
        }
    }

    const handleLogout = async () => {
        await GoogleAuthApi.logout()
        setIsLogin(false)
    }
    return (
        <AuthContext.Provider value={{ handleLogin, handleLogout, loading, isLogin, user, }}>
            {children}
        </AuthContext.Provider>
    )
}

export const useAuthContext = () => {
    const context = useContext(AuthContext)

    if (!context)
        throw new Error("AuthContext must be called from within the AuthContextProvider")

    return context
}

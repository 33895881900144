import {FC, useState} from "react";
import Dialog from "@mui/material/Dialog";
import {Button, DialogActions, DialogContent, DialogTitle, TextField} from "@mui/material";
import {References} from "../../api/reference-api";


type PropsType = {
    open: boolean
    loader: boolean
    handleClosePopup: () => void
    onSave: (value: string) => void
    title: References
}

export const AddReference: FC<PropsType> = ({open, handleClosePopup, onSave, title, loader}) => {
    const [value, setValue] = useState('')

    const handleSave = () => {
        onSave(value)
        setValue('')
    }

    const handleClose= () => {
        setValue('')
        handleClosePopup()
    }
    return <Dialog open={open} onClose={handleClosePopup}>
        <DialogTitle>Add {title}</DialogTitle>
        <DialogContent>
            <TextField
                disabled={loader}
                autoFocus
                margin="dense"
                label="Название"
                fullWidth
                variant="outlined"
                value={value}
                onChange={(e) => setValue(e.target.value)}
            />
        </DialogContent>
        <DialogActions>
            <Button onClick={handleClose} disabled={loader}>Отмена</Button>
            <Button onClick={handleSave} disabled={loader}>Добавить</Button>
        </DialogActions>
    </Dialog>
}

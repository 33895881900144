import React, {FC, useState} from "react";
import {
    Backdrop,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    TextField
} from "@mui/material";
import styles from "../items/items.module.css";
import DialogContentText from '@mui/material/DialogContentText';
import {useNavigate} from "react-router-dom";
import {ItemsApi} from "../../api/items-api";

type PropsType = {
    open: boolean
    onSuccess: (url: string) => Promise<any>
    navigateUrl: string
    title: string
}
export const ImportItems: FC<PropsType> = ({open, navigateUrl, onSuccess,title}) => {
    const [url, setUrl] = useState('')
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    const handleClose = () => {
        navigate(navigateUrl)
    }

    const handleSuccess = async () => {
        if (url) {
            setLoading(true)
            const result = await onSuccess(url)
            if (result) {
                window.open(url, '_blank')
                handleClose()
            }
            setLoading(false)
        }
    }
    return (
        <Dialog open={open} onClose={handleClose}>
            <Backdrop
                sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 999}}
                open={loading}
            >
                <CircularProgress color="inherit"/>
            </Backdrop>
            <div className={styles.AddItem}>
                <DialogTitle id="alert-dialog-title">
                    {`${title} import`}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Введите URL google sheet документа
                    </DialogContentText>

                </DialogContent>
                <DialogContent>
                    <FormControl fullWidth className={styles.formItem}>
                        <TextField label={'url'} value={url}
                                   size={'small'} InputLabelProps={{shrink: true}}
                                   onChange={(event) => setUrl(event.target.value as string)}/>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} disabled={loading} variant={'contained'}>Отмена</Button>
                    <Button onClick={handleSuccess} autoFocus disabled={loading} variant={'contained'}>
                        Загрузить
                    </Button>
                </DialogActions>
            </div>
        </Dialog>
    );
}

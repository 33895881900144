import {Item, ItemsApi} from "../../api/items-api";
import React, {useEffect, useState} from "react";
import {Outlet, useNavigate} from "react-router-dom";
import {useQuery, useQueryClient} from "react-query";
import {
    Backdrop, Checkbox,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, FormControl, InputLabel, TextField
} from "@mui/material";
import {JsonEditor} from "../../components/JsonEditor";
import styles from "./items.module.css";
import {ReferenceApi, References, ReferenceType} from "../../api/reference-api";
import {SelectClassifier} from "./form/SelectClasificator";

interface EditPopupProps {
    id: number
    onSave: (item: Item) => void; // Добавлено для сохранения изменений
}

export const EditItemPopup: React.FC<EditPopupProps> = ({id, onSave}) => {
    const [description, setDescription] = useState<string | null>(null)
    const [categoryId, setCategoryId] = useState(0)
    const [typeId, setTypeId] = useState(0)
    const [subtypeId, setSubtypeId] = useState(0)
    const [rarityId, setRarityId] = useState(0)
    const [name, setName] = useState<string | null>(null)
    const [isStack, setIsStack] = useState(false)
    const navigate = useNavigate()
    const {data, error, isLoading} = useQuery(['item', id], () => ItemsApi.getById(id), {cacheTime: 0});
    const handleOnClose = () => {
        navigate('/items')
    }

    const handleOnSave = (item: Item) => {
        onSave({
            ...item,
            subtype_id: subtypeId ,
            type_id: typeId,
            category_id: categoryId,
            rarity_id: rarityId,
            is_stack: isStack,
            description,
            name
        })
    }

    useEffect(() => {
        data?.description && setDescription(data.description)
        data?.name && setName(data.name)
    }, [data]);

    const handleSetCategoryId = (id: number) => {
        setTypeId(0)
        setSubtypeId(0)
        setCategoryId(id)
    }

    const handleSetTypeId = (id: number) => {
        setSubtypeId(0)
        setTypeId(id)
    }
    const queryClient = useQueryClient()
    const categories = queryClient.getQueryData<ReferenceType[]>('categories')
    const rarities = queryClient.getQueryData<ReferenceType[]>('rarities')
    const {
        data: category
    } = useQuery(['category', categoryId], () => ReferenceApi.getById(categoryId, References.categories), {cacheTime: 0});

    const {
        data: type
    } = useQuery(['type', typeId], () => ReferenceApi.getById(typeId, References.types), {cacheTime: 0});

    useEffect(() => {
        if (data) {
            setCategoryId(data.category_id || 0)
            setTypeId(data.type_id || 0)
            setSubtypeId(data.subtype_id || 0)
            setRarityId(data.rarity_id || 0)
            setIsStack(data.is_stack || false)
        }
    }, [data]);

    const selectsData = [
        {name: "categories", items: categories || [], value: categoryId, setValue: handleSetCategoryId, disable: false},
        {name: "types", items: category?.items || [], value: typeId, setValue: handleSetTypeId, disable: !categoryId},
        {name: "subtypes", items: type?.items || [], value: subtypeId, setValue: setSubtypeId, disable: !typeId},
        {name: "rarities", items: rarities || [], value: rarityId, setValue: setRarityId, disable: false},
    ]
    return (
        <Dialog open={true} onClose={handleOnClose}>
            <DialogTitle>Редактирование</DialogTitle>
            <Backdrop
                sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 999}}
                open={isLoading}
            >
                <CircularProgress color="inherit"/>
            </Backdrop>

            <DialogContent><img alt={''} width={80} src={data?.preview}/></DialogContent>
            <DialogContent className={styles.contentWrapper}>
                <div>
                    <FormControl fullWidth className={styles.formItem}>
                        <TextField placeholder={'X42-D8BC2B956E67F96D'} label={'Model ID'}
                                   value={data?.model_id} InputLabelProps={{shrink: true}}
                                   disabled={true}
                                   size={'small'}/>
                    </FormControl>
                    <FormControl fullWidth className={styles.formItem}>
                        <TextField  label={'Name'} value={name}
                                    size={'small'} InputLabelProps={{shrink: true}}
                                    onChange={(event) => setName(event.target.value as string)}/>
                    </FormControl>
                    <FormControl fullWidth className={styles.formItem}>
                        <TextField placeholder={''} label={'Description'} multiline={true} rows={4}
                                   value={description} InputLabelProps={{shrink: true}}
                                   onChange={(event) => setDescription(event.target.value as string)}/>
                    </FormControl>
                </div>
                <div>
                    {selectsData.map((s) => {
                        return <FormControl fullWidth className={styles.formItem}>
                            <SelectClassifier items={s.items} value={s.value} setValue={s.setValue} name={s.name}
                                              disable={s.disable}/>
                        </FormControl>
                    })}
                </div>
                <div className={styles.checkbox}>
                    <Checkbox id={'isStack'} checked={isStack} onChange={(event, checked) => {
                        setIsStack(checked)
                    }}/>
                    <InputLabel id={'isStack'}>isStack</InputLabel>
                </div>
            </DialogContent>
            <DialogContent>
                {data && <JsonEditor item={data} onSave={handleOnSave} onClose={handleOnClose}/>}
            </DialogContent>
            <DialogActions></DialogActions>
            <Outlet/>
        </Dialog>
    );
};

import {FC} from "react";
import Dialog from "@mui/material/Dialog";
import {Button, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import {inspect} from "util";
import styles from './add-result.module.css'
import {SliderProbability} from "./Slider";


export type OnClickType = 'item' | 'random' | 'Empty'

type PropsType = {
    open: boolean
    handleClosePopup: () => void
    onClick: (type: OnClickType) => void
    totalProbability: number
    setProbability: (value: number) => void
}

export const AddResult: FC<PropsType> = ({open, handleClosePopup, onClick, totalProbability, setProbability}) => {

    const handleClick = (type: OnClickType) => {
        onClick(type)
        handleClosePopup()
    }

    return <Dialog open={open} onClose={handleClosePopup}>
        <DialogTitle></DialogTitle>
        <DialogContent>
            Выберите тип результата
        </DialogContent>
        <DialogContent>
            <SliderProbability totalProbability={totalProbability} setProbability={setProbability}/>
        </DialogContent>
        <DialogContent className={styles.buttons}>
            <Button variant={'contained'} color={'success'} onClick={() => handleClick('item')}>Item</Button>
            <Button variant={'contained'} onClick={() => handleClick('random')}>Random</Button>
            <Button variant={'contained'} color={'warning'} onClick={() => handleClick('Empty')}>Empty</Button>
        </DialogContent>
        <DialogActions>
            <Button onClick={handleClosePopup}>Отмена</Button>
        </DialogActions>
    </Dialog>
}

import React, {FC, useEffect, useState} from 'react';
import Slider from '@mui/material/Slider';
import Typography from "@mui/material/Typography";
import {Input} from "@mui/material";
import styles from './slider.module.css'

type PropsType = {
    totalProbability: number
    setProbability: (value: number) => void
}
export const SliderProbability:FC<PropsType> = ({totalProbability, setProbability}) => {
    const maxSelectableValue = 100-totalProbability*100
    const [value, setValue] = useState(100-totalProbability*100); // Начальное значение
    const handleChange = (event: any, newValue: any) => {
        if (newValue <= maxSelectableValue) {
            setValue(+newValue);
            setProbability(Number((+newValue/100).toFixed(3)))
        }
    };
    const handleInputChange = (event: any) => {
        const newValue = Number(event.target.value)* 100
        setValue(newValue);
        setProbability(Number((newValue/100).toFixed(3)))
    };

    const handleBlur = () => {
        if (value < 0) {
            setValue(0);
        } else if (value > 100) {
            setValue(100);
        }
    };
    useEffect(() => {
        setProbability(Number((value/100).toFixed(3)))
    }, [totalProbability]);

    return (
        <div>
            <Typography id="input-slider" gutterBottom>
               Вероятность: {(value/100).toFixed(3)} ({value.toFixed(1)}%)
            </Typography>
            <Slider
                className={styles.slider}
                value={value}
                onChange={handleChange}
                min={0}
                max={100}
                step={0.001}
                aria-labelledby="input-slider"
                // Дополнительные пропсы, если нужны (например, step)
            />
            <div className={styles.input}>
                <p>Введите вручную</p>
                <div>
                    <Input
                        value={value/100}
                        margin="dense"
                        onChange={handleInputChange}
                        onBlur={handleBlur}
                        inputProps={{
                            step: 0.001,
                            min: 0.001,
                            max: 1,
                            type: 'number',
                            'aria-labelledby': 'input-slider',
                        }}
                    />
                </div>

            </div>

        </div>
    );
}


import React, {useState} from 'react';
import {Button} from "@mui/material";
import styles from './json-editor.module.css'
import {Item} from "../api/items-api";


type PropsType = {
    item: Item
    onSave: (item: Item) => void; // Добавлено для сохранения изменений
    onClose: () => void
    loading?: boolean
}
export const JsonEditor: React.FC<PropsType> = ({item, onSave, onClose, loading}) => {
    const [jsonInput, setJsonInput] = useState<string>(JSON.stringify(item.properties, null, 4));
    const [isValidJson, setIsValidJson] = useState<boolean>(true);
    const [error, setError] = useState<string>('');
    const [formattedJson, setFormattedJson] = useState<string>('');
    const validateAndFormatJson = (input: string) => {
        try {
            const parsedJson = JSON.parse(input);
            const formatted = JSON.stringify(parsedJson, null, 4);
            setFormattedJson(formatted);
            setIsValidJson(true);
            setError('');
        } catch (e) {
            setIsValidJson(false);
            setError(e instanceof Error ? e.message : 'Unknown error');
        }
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setJsonInput(e.target.value);
        validateAndFormatJson(e.target.value);
    };

    const formatJson = () => {
        try {
            const parsedJson = JSON.parse(jsonInput);
            setJsonInput(JSON.stringify(parsedJson, null, 4));
            setIsValidJson(true);
            setError('');
        } catch (e) {
            setIsValidJson(false);
            setError('Invalid JSON');
        }
    };

    const handleOnSave = () => {
        onSave({
            ...item,
            properties: JSON.parse(jsonInput)
        })
    }
    return (
        <div>
            <div className={styles.title}>Properties:</div>
            <textarea
                value={jsonInput}
                onChange={handleInputChange}
                rows={15}
                cols={66}
                style={{fontFamily: 'monospace'}}
                className={styles.textarea}
            ></textarea>
            {!isValidJson && <div style={{color: 'red'}}>{error}</div>}
            <div className={styles.buttonWrapper}>
                <Button className={styles.buttons} disabled={!isValidJson || loading} onClick={handleOnSave}
                        variant={'contained'}>Сохранить</Button>
                <Button className={styles.buttons} onClick={formatJson} variant={'contained'}>Format JSON</Button>
                <Button className={styles.buttons} onClick={onClose} disabled={loading} variant={'contained'}
                        color={'error'}>Закрыть</Button>

            </div>

        </div>
    );
};


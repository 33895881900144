import React, {FC} from "react";
import {InputLabel, MenuItem, Select} from "@mui/material";
import {ReferenceType} from "../../../api/reference-api";

type PropsType = {
    name: string
    value: number
    setValue: (value: number) =>void
    items: ReferenceType[]
    disable: boolean
}
export const SelectClassifier: FC<PropsType> = ({name, value, setValue, items, disable}) => {
    return <><InputLabel id={name}>{name}</InputLabel>
        <Select
            labelId={name}
            value={value}
            label={name}
            size={'small'}
            disabled={disable}
            onChange={(event, child) => setValue(+event.target.value)}
        >
            {items?.map((c) => {
                return <MenuItem value={c.id}>{c.name}</MenuItem>
            })}
            <MenuItem value={0}>NONE</MenuItem>

        </Select></>
}

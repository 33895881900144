import React, {FC} from 'react';
import {CredentialResponse, GoogleLogin} from "@react-oauth/google";
import styles from './google.module.css'

type PropsType = {
    onSuccess: (response: CredentialResponse) => void
}

export const GoogleLoginButton: FC<PropsType> = ({onSuccess}) => {
    return <div className={styles.wrapper}>
        <GoogleLogin
            onSuccess={onSuccess}
            onError={() => {
                console.log('Login Failed');
            }}
            auto_select={false}
        /></div>;

}

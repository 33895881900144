import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import {GoogleLoginButton} from "../components/login/google/GoogleLogin";
import styles from './login.module.css'
import {CredentialResponse} from "@react-oauth/google";
import {FC} from "react";

type PropsType = {
    onSuccess: (response: CredentialResponse) => void
}
export const LoginCard: FC<PropsType> = ({onSuccess}) => {
    return (
        <Card sx={{minWidth: 275, maxWidth: 275, minHeight: 200}} className={styles.loginButton}>
            <CardContent>
                <Typography sx={{fontSize: 14}} color="text.secondary" gutterBottom>
                    <GoogleLoginButton onSuccess={onSuccess}/>
                </Typography>
            </CardContent>
        </Card>
    );
}

import React, {FC} from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import styles from "../items/items.module.css";
import DialogContentText from '@mui/material/DialogContentText';

type PropsType = {
    open: boolean
    handleClose: () => void
    handleDelete: () => void
}
export const DeleteRecipesDialog: FC<PropsType> = ({open, handleClose, handleDelete}) => {
    return (
        <Dialog open={open} onClose={handleClose}>
            <div className={styles.AddItem}>
                <DialogTitle id="alert-dialog-title">
                    {"Удаление рецепта"}
                </DialogTitle>
                <DialogContent className={styles.contentWrapper}>
                    <DialogContentText id="alert-dialog-description">
                        Вы действительно хотите удалить рецепт?
                    </DialogContentText>
                </DialogContent>

                <DialogActions>
                    <Button onClick={handleClose}>Отмена</Button>
                    <Button onClick={handleDelete} autoFocus>
                        Удалить
                    </Button>
                </DialogActions>
            </div>
        </Dialog>
    );
}

import React, {FC, useState} from "react";
import {IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import styles from "../items.module.css";
import ArchiveIcon from '@mui/icons-material/Archive';
import UnarchiveIcon from '@mui/icons-material/Unarchive';
import {Item, ItemsApi, PaginatedResponse} from "../../../api/items-api";
import {format} from 'date-fns';
import {Outlet, useNavigate} from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import {useQueryClient} from "react-query";

type PropsType = {
    data?: PaginatedResponse<Item>
    rowClick?: (item: Item) => void
}
export const ItemsTable: FC<PropsType> = ({data, rowClick}) => {
    const [disableArchive, setDisableArchive] = useState(false)
    const navigate = useNavigate()
    const formatDate = (dateString: string): string => {
        const date = new Date(dateString);
        return format(date, 'dd.MM.yyyy HH:mm');
    };
    const queryClient = useQueryClient()
    const refetchSomeKeyQuery = async () => {
        await queryClient.invalidateQueries('items');
    };
    const handleEditClick = (id?: number) => {
        navigate(`/items/${id}`)
    };

    const handleIsArchive = async (isArchive: boolean, id?: number) => {
        setDisableArchive(true)
        id && await ItemsApi.archive({isArchive}, id)
        await refetchSomeKeyQuery()
        setDisableArchive(false)
    }
    return <TableContainer component={Paper}>
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Preview</TableCell>
                    <TableCell>Model ID</TableCell>
                    <TableCell>Category</TableCell>
                    <TableCell>Type</TableCell>
                    <TableCell>Sub Type</TableCell>
                    <TableCell>Rarity</TableCell>
                    <TableCell>Created At</TableCell>
                    <TableCell>Updated At</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {data?.items.map((item) => (
                    <TableRow key={item.id} onClick={() => rowClick && rowClick(item)} className={styles.tableRow}>
                        <TableCell>{item.id}</TableCell>
                        <TableCell><img alt={''} className={styles.Image} src={item.preview}/></TableCell>
                        <TableCell>{item.name}</TableCell>
                        <TableCell>{item.model_id}</TableCell>
                        <TableCell>{item.category?.name}</TableCell>
                        <TableCell>{item.type?.name}</TableCell>
                        <TableCell>{item.sub_type?.name}</TableCell>
                        <TableCell>{item.rarity?.name}</TableCell>
                        <TableCell>{item.created_at && formatDate(item.created_at)}</TableCell>
                        <TableCell>{item.updated_at && formatDate(item.updated_at)}</TableCell>
                        {!rowClick && <TableCell>
                            <IconButton onClick={() => handleEditClick(item?.id)}>
                                <EditIcon/>
                            </IconButton>
                        </TableCell>}
                        {!rowClick && <TableCell>
                            <IconButton disabled={disableArchive}>
                                {item.is_archive ? <UnarchiveIcon onClick={() => handleIsArchive(false, item?.id)}/> :
                                    <ArchiveIcon onClick={() => handleIsArchive(true, item?.id)}/>}
                            </IconButton>
                        </TableCell>}
                    </TableRow>
                ))}
            </TableBody>
        </Table>
        <Outlet/>
    </TableContainer>
}

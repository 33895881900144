import React, {useEffect, useState} from "react";
import {useQuery, useQueryClient} from "react-query";
import {
    Backdrop,
    Button,
    Checkbox,
    CircularProgress,
    FormControl,
    InputLabel,
    MenuItem,
    Pagination, Select,
    TextField
} from "@mui/material";

import {Item, ItemsApi} from "../../api/items-api";
import {NavLink, Outlet, useLocation, useNavigate, useParams} from "react-router-dom";
import {EditItemPopup} from "./EditItem";
import styles from './items.module.css'
import {ItemsTable} from "./table/ItemsTable";
import {ReferenceType} from "../../api/reference-api";
import {SelectClassifier} from "./form/SelectClasificator";

type PropsType = {
    rowClick?: (item: Item) => void
}
export const ItemsPage: React.FC<PropsType> = ({rowClick}) => {
    const [page, setPage] = useState(1);
    const [modelId, setModelId] = useState('');
    const [categoryId, setCategoryId] = useState(0)
    const [typeId, setTypeId] = useState(0)
    const [subtypeId, setSubtypeId] = useState(0)
    const [rarityId, setRarityId] = useState(0)
    const [pageSize, setPageSize] = useState(50); // или любое другое значение по умолчанию
    const [isArchive, setIsArchive] = useState(false); // или любое другое значение по умолчанию
    const [loading, setLoading] = useState(false); // или любое другое значение по умолчанию
    const navigate = useNavigate()
    const {itemId} = useParams<{ itemId: string }>()
    const location = useLocation()

    const {
        data,
        error,
        isLoading,
        refetch
    } = useQuery(['items', page, pageSize, modelId, categoryId, typeId, subtypeId, rarityId, isArchive], () => ItemsApi.getAll(page, pageSize, modelId, categoryId, typeId, subtypeId, rarityId, isArchive), {cacheTime: 0});

    useEffect(() => {
        refetch()
    }, [location.pathname])
    const handlePageChange = (_event: React.ChangeEvent<unknown>, value: number): void => {
        setPage(value);
    };
    const handleUpdateItem = async (item: Item) => {
        setLoading(true)
        item.id && await ItemsApi.update({
            properties: item.properties,
            description: item.description,
            category_id: item.category_id || null,
            subtype_id: item.subtype_id || null,
            rarity_id: item.rarity_id || null,
            type_id: item.type_id || null,
            is_stack: item.is_stack,
        }, item.id)
        setLoading(false)
        navigate('/items')
    }
    const queryClient = useQueryClient()
    const categories = queryClient.getQueryData<ReferenceType[]>('categories')
    const rarities = queryClient.getQueryData<ReferenceType[]>('rarities')
    const types = queryClient.getQueryData<ReferenceType[]>('types')
    const subtypes = queryClient.getQueryData<ReferenceType[]>('subtypes')
    const selectsData = [
        {name: "categories", items: categories || [], value: categoryId, setValue: setCategoryId, disable: false},
        {name: "types", items: types || [], value: typeId, setValue: setTypeId, disable: false},
        {name: "subtypes", items: subtypes || [], value: subtypeId, setValue: setSubtypeId, disable: false},
        {name: "rarities", items: rarities || [], value: rarityId, setValue: setRarityId, disable: false},
    ]

    const handlePageSizeChange = (event: any) => {
        setPageSize(event.target.value);
        setPage(1); // Сбросить на первую страницу при изменении размера страницы
    };


    return (
        <div className={styles.Items}>
            <Backdrop
                sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                open={loading && isLoading}
            >
                <CircularProgress color="inherit"/>
            </Backdrop>
            <div className={styles.find}>
                <TextField placeholder={'X42-D8BC2B956E67F96D'} label={'Find by Model ID'} value={modelId}
                           className={styles.modelId} InputLabelProps={{shrink: true}}
                           onChange={(event) => setModelId(event.target.value as string)}/>
                {selectsData.map((s) => {
                    return <FormControl fullWidth className={styles.formItem}>
                        <SelectClassifier items={s.items} value={s.value} setValue={s.setValue} name={s.name}
                                          disable={s.disable}/>
                    </FormControl>
                })}
            </div>
            <div className={styles.checkbox}>
                <Checkbox id={'isArchive'} value={isArchive} onChange={(event, checked) => {
                    setIsArchive(checked)
                    setPage(1)
                }}/>
                <InputLabel id={'isArchive'}>Показать архив</InputLabel>
            </div>
            {!rowClick && <div className={styles.addItemButton}>
                <NavLink to={'/items/add'}><Button variant={'contained'}>Add
                    Item</Button></NavLink>
                <NavLink to={'/items/import'}><Button variant={'contained'}>Import</Button></NavLink>
            </div>}

            <ItemsTable data={data} rowClick={rowClick}/>
            {itemId && <EditItemPopup id={Number(itemId)} onSave={handleUpdateItem}/>}
            <div className={styles.pagination}>
                <FormControl variant="outlined" size="small">
                    <Select
                        value={pageSize}
                        onChange={handlePageSizeChange}
                        displayEmpty
                        inputProps={{ 'aria-label': 'Without label' }}
                    >
                        <MenuItem value={50}>50</MenuItem>
                        <MenuItem value={100}>100</MenuItem>
                        <MenuItem value={200}>200</MenuItem>
                        <MenuItem value={300}>300</MenuItem>
                        <MenuItem value={500}>500</MenuItem>
                    </Select>
                </FormControl>
                <Pagination
                    boundaryCount={data?.totalPages}
                    count={data?.totalPages}
                    page={page}
                    onChange={handlePageChange}
                />

            </div>
            <Outlet/>
        </div>
    );
}





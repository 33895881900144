import React, {useState} from "react";
import {useQuery} from "react-query";
import {
    Backdrop,
    Button,
    CircularProgress, FormControl, IconButton, MenuItem,
    Pagination,
    Paper, Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from "@mui/material";
import {format} from 'date-fns';
import {NavLink, Outlet} from "react-router-dom";
import styles from './recipes.module.css'
import {RecipesApi} from "../../api/recipes-api";
import {Square} from "./Square";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {DeleteRecipesDialog} from "./DeleteRecipesDialog";

export const Recipes: React.FC = () => {
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(50); // или любое другое значение по умолчанию
    const [loading, setLoading] = useState(false); // или любое другое значение по умолчанию
    const [deleteId, setDeleteId] = useState<number | null>(null)
    const [deleteOpen, setDeleteOpen] = useState(false)
    const {
        data,
        isLoading,
        refetch
    } = useQuery(['recipes', page, pageSize], () => RecipesApi.getAll(page, pageSize), {cacheTime: 0});

    const handlePageChange = (_event: React.ChangeEvent<unknown>, value: number): void => {
        setPage(value);
    };

    const formatDate = (dateString: string): string => {
        const date = new Date(dateString);
        return format(date, 'dd.MM.yyyy HH:mm');
    };

    const handleDelete = async () => {
        setLoading(true)
        deleteId && await RecipesApi.delete(deleteId)
        await refetch()
        setLoading(false)
    };

    const handlePageSizeChange = (event: any) => {
        setPageSize(event.target.value);
        setPage(1); // Сбросить на первую страницу при изменении размера страницы
    };

    return (
        <div className={styles.Items}>
            <Backdrop
                sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                open={loading && isLoading}
            >
                <CircularProgress color="inherit"/>
            </Backdrop>
            <div className={styles.find}>
                <NavLink to={'/recipes/add'} className={styles.addItemButton}><Button variant={'contained'}>Add
                    Recipes</Button></NavLink>
                <NavLink to={'/recipes/import'}><Button variant={'contained'}>Import</Button></NavLink>
            </div>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell>Items</TableCell>
                            <TableCell>Created At</TableCell>
                            <TableCell>Updated At</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data?.items.map((item) => (
                            <TableRow key={item.id}>
                                <TableCell>{item.id}</TableCell>
                                <TableCell className={styles.items}>{
                                    Object.keys(item.items.split('-'))
                                        .map((i) => {
                                            return <span>{item.items_preview[i] &&
                                                <img alt={''} width={40} src={item.items_preview[i]}/>}</span>
                                        })}</TableCell>
                                <TableCell>{item.created_at && formatDate(item.created_at)}</TableCell>
                                <TableCell>{item.updated_at && formatDate(item.updated_at)}</TableCell>
                                <TableCell>
                                    <IconButton onClick={() => {
                                        setDeleteOpen(true)
                                        setDeleteId(item.id)
                                    }} disabled={loading}>
                                        <DeleteIcon/>
                                    </IconButton>
                                    <NavLink to={`/recipes/add/${item.id}`} className={styles.addItemButton}>
                                        <IconButton disabled={loading}>
                                            <EditIcon/>
                                        </IconButton>
                                    </NavLink>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <div className={styles.pagination}>
                <FormControl variant="outlined" size="small">
                    <Select
                        value={pageSize}
                        onChange={handlePageSizeChange}
                        displayEmpty
                        inputProps={{ 'aria-label': 'Without label' }}
                    >
                        <MenuItem value={50}>50</MenuItem>
                        <MenuItem value={100}>100</MenuItem>
                        <MenuItem value={200}>200</MenuItem>
                        <MenuItem value={300}>300</MenuItem>
                        <MenuItem value={500}>500</MenuItem>
                    </Select>
                </FormControl>
                <Pagination
                    boundaryCount={data?.totalPages}
                    count={data?.totalPages}
                    page={page}
                    onChange={handlePageChange}
                />
            </div>
            <DeleteRecipesDialog open={deleteOpen} handleClose={() => {
                setDeleteOpen(false)
                setDeleteId(null)
            }
            } handleDelete={handleDelete}/>
            <Outlet/>
        </div>
    );
}





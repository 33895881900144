import {MegaAppAxiosInstance} from "./axios.instance";

export enum ItemTypeEnum {
    SKIN = 'SKIN',
    GOODS = 'GOODS',
}

export class ItemPropertiesDto {
    [key: string]: any;
}

export enum ItemSubTypeEnum {
    H = 'H',
    A = 'A',
    B = 'B',
    L = 'L',
}

export type Classifications = {
    name: string
}

export interface Item {
    id?: number;
    model_id?: string | null;
    type?: Classifications;
    category?: Classifications;
    type_id?: number;
    category_id?: number;
    subtype_id?: number;
    rarity_id?: number;
    sub_type?: Classifications;
    rarity?: Classifications;
    is_archive?: boolean;
    created_at?: string;
    updated_at?: string;
    properties: ItemPropertiesDto
    preview?: string
    description?: string | null
    is_stack?: boolean
    name?: string | null
}

export interface PaginatedResponse<T> {
    items: T[];
    totalItems: number;
    totalPages: number;
    currentPage: number;
}

export type CreateItemDto = {
    model_id?: string;
    type_id?: number | null;
    subtype_id?: number | null;
    category_id?: number | null;
    rarity_id?: number | null;
    properties?: ItemPropertiesDto;
    description?: string | null
    is_stack?: boolean
    name?: string | null

}

export type ItemIsArchive = {
    isArchive: boolean;
}
export const ItemsApi = {

    async getAll(page: number, pageSize: number, modelId?: string, category_id?: number,
                 type_id?: number,
                 subtype_id?: number,
                 rarity_id?: number, isArchive?: boolean): Promise<PaginatedResponse<Item>> {
        try {
            return MegaAppAxiosInstance.get<PaginatedResponse<Item>>('/items', {
                params: {
                    page,
                    pageSize,
                    modelId,
                    category_id,
                    type_id,
                    subtype_id,
                    rarity_id,
                    isArchive
                }
            }).then((r) => r.data)
        } catch (error) {
            console.log(error)
            throw new Error('Ошибка при выполнении запроса');
        }
    },

    async getById(id?: number): Promise<Item> {
        try {
            return MegaAppAxiosInstance.get<Item>(`/items/${id}`).then((r) => r.data)
        } catch (error) {
            console.log(error)
            throw new Error('Ошибка при выполнении запроса');
        }
    },

    create(dto: CreateItemDto) {
        return MegaAppAxiosInstance.post('/items', dto)
    },

    update(dto: CreateItemDto, id: number) {
        return MegaAppAxiosInstance.put(`/items/${id}`, dto)
    },

    archive(dto: ItemIsArchive, id: number) {
        return MegaAppAxiosInstance.patch(`/items/${id}`, dto)
    },

    import(url: string) {
        return MegaAppAxiosInstance.patch<boolean>(`/items/import`, {url}).then((r) => r.data)
    }
}


import * as React from "react";
import {FC, useEffect, useState} from "react";
import {Square} from "./Square";
import styles from './recipes.module.css'
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import {Button, Checkbox, InputLabel} from "@mui/material";
import {NavLink, useNavigate, useParams} from "react-router-dom";
import {CraftResult, RecipesApi} from "../../api/recipes-api";
import {AddResult} from "./AddResult";
import {FindItem} from "./FindItems";
import {Item} from "../../api/items-api";
import {References} from "../../api/reference-api";
import {FindReference} from "./FindReference";
import {HtmlTooltip} from "./Tooltip";
import Typography from "@mui/material/Typography";

type PropsType = {}

export const AddRecipes: FC<PropsType> = () => {
    const [items, setItems] = useState<number[]>([]);
    const [itemsJson, setItemsJson] = useState<Item[]>([]);
    const [itemsPreview, setItemsPreview] = useState<any>({});
    const [results, setResults] = useState<CraftResult[]>([]);
    const [resultsPreview, setResultsPreview] = useState<any>({});
    const [loading, setLoading] = useState(false)
    const [typeResult, setTypeResult] = useState('')
    const [probability, setProbability] = useState(0)

    const [openPopup, setOpenPopup] = useState(false);
    const [isChoice, setIsChoice] = useState(false);

    const {recipesId} = useParams<{ recipesId: string }>()

    const handleFetchRecipesById = async (id: number) => {
        const initialData = await RecipesApi.getById(id)
        initialData?.items && setItems(initialData.items.split('-').map((i) => Number(i)))
        initialData?.results_json && setResults(initialData.results_json)
        initialData?.items_preview && setItemsPreview(initialData.items_preview)
        initialData?.results_preview && setResultsPreview(initialData.results_preview)
        initialData?.items_json && setItemsJson(initialData.items_json)
    }

    useEffect(() => {
        if (recipesId) {
            handleFetchRecipesById(+recipesId)
        }
    }, [recipesId]);

    const navigate = useNavigate()
    const handleSave = async () => {
        if (items.length && results.length) {
            setLoading(true)
            if (!recipesId) {
                await RecipesApi.create({
                    items: items.join('-'),
                    results: '',
                    items_preview: itemsPreview,
                    results_preview: resultsPreview,
                    results_json: results,
                    is_choice: isChoice
                })
            } else {
                await RecipesApi.update(+recipesId, {
                    items: items.join('-'),
                    results: '',
                    items_preview: itemsPreview,
                    results_preview: resultsPreview,
                    results_json: results,
                    is_choice: isChoice
                })

            }
            setLoading(false)
            navigate('/recipes')
        }

    }

    const handleOnSelect = (item: Item) => {
        const newResults = [...results];
        newResults.push({itemId: item.id, type: 'item', probability, preview: item.preview})
        setResults(newResults);
        setTypeResult('')
    }

    const handleDeleteResultItem = (itemId: number) => {
        const newResults = results.filter((r) => r.itemId !== itemId)
        setResults(newResults);
    }

    const handleDeleteResultRandom = (type_value: number) => {
        const newResults = results.filter((r) => r.type_value !== type_value)
        setResults(newResults);
    }
    const handleOnReference = (id: number, type: References, title: string, rarity_id?: number, rarity_title?: string) => {
        const newResults = [...results];
        newResults.push({type, probability, type_value: id, title, rarity_id, rarity_title})
        setResults(newResults);
        setTypeResult('')
    }

    const handleEmptyResult = () => {
        const newResults = [...results];
        newResults.push({type: 'Empty', probability, type_value: -1})
        setResults(newResults);
        setTypeResult('')
    }

    useEffect(() => {
        if (typeResult === 'Empty' ) {
            handleEmptyResult()
        }
    }, [typeResult]);

    const itemsResult = results.filter((r) => r.type === 'item')
    const randomResult = results.filter((r) => r.type !== 'item')

    const totalProbability = results.reduce((sum, item) => sum + item.probability, 0).toFixed(3);
    return <div>
        <div className={styles.recipesId}>Recipes ID: {recipesId}</div>
        <div className={styles.wrapper}>
            <div className={styles.cardWrapper}>
                <Card >
                    <CardContent>
                        <Square count={9} setItems={setItems} setPreview={setItemsPreview} previews={itemsPreview}
                                items={items} items_json={itemsJson}/>
                    </CardContent>
                </Card>
            </div>
            <div className={styles.cardSpace}>
                =
            </div>
            <div className={styles.cardWrapper}>
                <Card >
                    <CardContent>
                        <Square count={itemsResult.length} setItems={() => {
                        }} setPreview={() => {
                        }}
                                previews={itemsResult.reduce((r, result, index) => {
                                    r[index] = result.preview
                                    return r
                                }, {} as any)} items={itemsResult.map((r) => r.itemId as number)} results={results}
                                handleDeleteResultItem={handleDeleteResultItem}
                                items_json={itemsJson}
                        />

                        {randomResult.map((r) => {
                            return <div
                                className={styles.randomItem}>{r.rarity_title} {r.title} probability: {r.probability} ({(r.probability * 100).toFixed(1)+`%`})
                                <div className={styles.deleteIcon} onClick={(event) =>  {
                                   r.type_value && handleDeleteResultRandom(r.type_value)
                                }}>X
                                </div>
                            </div>
                        })}
                        <Button variant={'contained'} color={'success'} disabled={+totalProbability === 1}
                                onClick={() => setOpenPopup(true)}>Добавить</Button>

                        {/*<Square count={2} setItems={setResults} setPreview={setResultsPreview}*/}
                        {/*        previews={resultsPreview} items={results}/>*/}
                        <div className={styles.checkbox}>
                            <Checkbox id={'isChoice'} value={isChoice} onChange={(event, checked) => {
                                setIsChoice(checked)
                            }}/>
                            <InputLabel id={'isChoice'}>Выбор</InputLabel>
                        </div>
                    </CardContent>

                </Card>
            </div>
        </div>
        <AddResult setProbability={setProbability} onClick={setTypeResult} handleClosePopup={() => setOpenPopup(false)}
                   open={openPopup} totalProbability={+totalProbability}/>
        <FindItem open={typeResult === 'item'} setOpen={() => setTypeResult('')} onSelect={handleOnSelect}/>
        <FindReference open={typeResult === 'random'} setOpen={() => setTypeResult('')} onSelect={handleOnReference}/>
        <div className={styles.wrapperButton}>
            <Button variant={'contained'} color={'success'} disabled={loading || !items.length || !results.length}
                    onClick={handleSave}>Сохранить</Button>
            <NavLink to={'/recipes'}> <Button variant={'contained'} disabled={loading} color={'warning'}>Отмена</Button></NavLink>
        </div>
    </div>
}

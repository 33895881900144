import React from "react";
import styles from './header.module.css'
import {AppBar, Toolbar} from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';

type PropsType = {
    setIsOpenMenu: (value: boolean) => void
}
export const Header: React.FC<PropsType> = ({setIsOpenMenu}) => {
    return (
        <div className={styles.mainContent}>
            <AppBar position="fixed" className={styles.Header}>
                <Toolbar>
                    <div onClick={() => setIsOpenMenu(true)}><MenuIcon className={styles.menu}/></div>
                </Toolbar>
            </AppBar>
        </div>
    );
}

import React, {FC, useState} from "react";
import {useQuery, useQueryClient} from "react-query";
import {ReferenceApi, References, ReferenceType} from "../../api/reference-api";
import styles from './references.module.css';
import {Button, CircularProgress} from "@mui/material";
import {AddReference} from "./AddReference";

type PropsType = {
    onSelect?: (id: number, type: References, title: string, rarity_id?: number, rarity_title?: string) => void
}

type AddElementType = {
    type: References,
    rarity_id?: number
    id?: number
}

export const ReferencesPage: FC<PropsType> = ({onSelect}) => {
    const [selectedCategory, setSelectedCategory] = useState(0);
    const [selectedType, setSelectedType] = useState(0);
    const [selectedSubtype, setSelectedSubtype] = useState(0);
    const [selectedRarity, setSelectedRarity] = useState(0);
    const [openPopup, setOpenPopup] = useState(false);
    const [addElement, setAddElement] = useState<AddElementType>({type: References.categories});
    const [loader, setLoader] = useState(false)

    const [findCategory, setFindCategory] = useState<any>()

    const queryClient = useQueryClient()
    const categories = queryClient.getQueryData<ReferenceType[]>('categories')
    const rarities = queryClient.getQueryData<ReferenceType[]>('rarities')
    const queryStateCategories = queryClient.getQueryState('categories');
    const queryStateRarities = queryClient.getQueryState('rarities');
    const categoryLoading = queryStateCategories?.isFetching;
    const raritiesLoading = queryStateRarities?.isFetching;
    const {
        data: category,
        isLoading: typesLoading,
        refetch: typesRefetch
    } = useQuery(['category', selectedCategory], () => ReferenceApi.getById(selectedCategory, References.categories), {cacheTime: 0});

    const {
        data: type,
        isLoading: subtypesLoading,
        refetch: subtypesRefetch
    } = useQuery(['type', selectedType], () => ReferenceApi.getById(selectedType, References.types), {cacheTime: 0});


    const handleCategoryClick = (categoryId: number) => {
        setSelectedCategory(categoryId);
        setFindCategory({
            ...findCategory,
            type: References.categories,
            title: categories?.find((c) => c.id === categoryId)?.name,
            id: categoryId
        })
        setSelectedType(0);
    };

    const handleTypeClick = (typeId: number) => {
        setFindCategory({
            ...findCategory,
            type: References.types,
            title: category?.items?.find((c) => c.id === typeId)?.name,
            id: typeId
        })
        setSelectedType(typeId);
    };

    const handleSubTypeClick = (subtypeId: number) => {
        setSelectedSubtype(subtypeId)
        setFindCategory({
            ...findCategory,
            type: References.subtypes,
            title: type?.items?.find((i) => i.id === subtypeId)?.name,
            id: subtypeId
        })
    };

    const handleRarityClick = (rarityId: number) => {
        setSelectedRarity(rarityId)
        setFindCategory({
            ...findCategory,
            type: findCategory?.type || References.rarities,
            title: findCategory?.title || rarities?.find((i) => i.id === rarityId)?.name,
            rarity_id: rarityId,
            id: findCategory?.id || rarityId,
            rarity_title: rarities?.find((i) => i.id === rarityId)?.name
        })
    };

    const handleClosePopup = () => {
        setOpenPopup(false)
    };

    const handleCreate = async (value: string) => {
        setLoader(true)
        const {id, type} = addElement
        await ReferenceApi.create(type, value, id)
        switch (type) {
            case References.types: {
                await typesRefetch()
                break;
            }
            case References.subtypes: {
                await subtypesRefetch()
                break;
            }
            case References.categories: {
                await queryClient.invalidateQueries('categories');
                break;
            }
            case References.rarities: {
                await queryClient.invalidateQueries('rarities');
                break;
            }
        }
        setLoader(false)
        handleClosePopup()
    }

    const handleOpenPopup = (addElement: AddElementType) => {
        setAddElement(addElement)
        setOpenPopup(true)
    }


    return (
        <div className={styles.container}>
            <div className={styles.column}>
                {!onSelect && <Button className={styles.addButton} variant={'contained'} disabled={loader}
                                      onClick={() => handleOpenPopup({type: References.categories})}>Добавить</Button>}
                <div className={styles.header}>Categories</div>
                {categoryLoading ? <CircularProgress/> :
                    categories?.map(category => (
                        <div
                            key={category.id}
                            onClick={() => handleCategoryClick(category.id)}
                            className={`${styles.item} ${selectedCategory === category.id ? styles.selectedItem : ''}`}
                        >
                            {category.name}
                        </div>
                    ))}
            </div>

            <div className={styles.column}>
                {!onSelect && <Button className={styles.addButton} variant={'contained'}
                                      disabled={!selectedCategory || loader}
                                      onClick={() => handleOpenPopup({
                                          type: References.types,
                                          id: category?.id
                                      })}>Добавить</Button>}
                <div className={styles.header}>Types</div>
                {typesLoading ? <CircularProgress/> :
                    category?.items?.map((type: ReferenceType) => (
                        <div
                            key={type.id}
                            onClick={() => handleTypeClick(type.id)}
                            className={`${styles.item} ${selectedType === type.id ? styles.selectedItem : ''}`}
                        >
                            {type.name}
                        </div>
                    ))}

            </div>

            <div className={styles.column}>
                {!onSelect &&
                    <Button className={styles.addButton} variant={'contained'} disabled={!selectedType || loader}
                            onClick={() => handleOpenPopup({
                                type: References.subtypes,
                                id: type?.id
                            })}>Добавить</Button>}
                <div className={styles.header}>Subtypes</div>
                {subtypesLoading ? <CircularProgress/> :
                    type?.items?.map((subtype: ReferenceType) => (
                        <div key={subtype.id} onClick={() => handleSubTypeClick(subtype.id)}
                             className={`${styles.item} ${selectedSubtype === subtype.id ? styles.selectedItem : ''}`}
                        >
                            {subtype.name}
                        </div>
                    ))}
            </div>
            <div className={styles.rarities}>
                <div className={styles.column}>
                    {!onSelect && <Button className={styles.addButton} variant={'contained'} disabled={loader}
                                          onClick={() => handleOpenPopup({
                                              type: References.rarities,
                                              id: type?.id
                                          })}>Добавить</Button>}
                    <div className={styles.header}>Rarity</div>
                    {raritiesLoading ? <CircularProgress/> :
                        rarities?.map((rarity: ReferenceType) => (
                            <div key={rarity.id} onClick={() => handleRarityClick(rarity.id)}
                                 className={`${styles.item} ${selectedRarity === rarity.id ? styles.selectedItem : ''}`}
                            >
                                {rarity.name}
                            </div>
                        ))}
                </div>
            </div>

            {!onSelect && <AddReference title={addElement.type} open={openPopup} handleClosePopup={handleClosePopup}
                                        onSave={handleCreate} loader={loader}/>}
            {onSelect && <Button className={styles.addButton} variant={'contained'} disabled={loader}
                                 onClick={() => onSelect(findCategory.id, findCategory.type, findCategory.title, findCategory.rarity_id, findCategory.rarity_title)}>Добавить</Button>}
        </div>
    );
};


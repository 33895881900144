import React from 'react';
import { Drawer, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import {NavLink} from "react-router-dom";
import ListAltIcon from '@mui/icons-material/ListAlt';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import Divider from "@mui/material/Divider";
import CategoryIcon from '@mui/icons-material/Category';
import TypeSpecimenIcon from '@mui/icons-material/TypeSpecimen';
import ClosedCaptionIcon from '@mui/icons-material/ClosedCaption';
import CameraRearIcon from '@mui/icons-material/CameraRear';

const menuItems = [
    { text: 'Items', path: '/items', icon: <ListAltIcon/> },
    { text: 'Recipes', path: '/recipes', icon: <AutoFixHighIcon/> },
    { text: 'References', path: '/references', icon: <CategoryIcon/> },
    // Добавьте другие пункты меню здесь
];


type PropsType = {
    open: boolean
    setIsOpenMenu: (value: boolean) => void
}
export const Sidebar: React.FC<PropsType> = ({open,setIsOpenMenu}) => {
    return (
        <Drawer
            // variant="permanent"
            anchor="left"
            open={open}
            onClose={() => setIsOpenMenu(false)}
        >
            <List>
                {menuItems.map((item, index) => (
                    <ListItem button key={index} component={NavLink} to={item.path} onClick={() => setIsOpenMenu(false)}>
                        <ListItemIcon>{item.icon}</ListItemIcon>
                        <ListItemText primary={item.text}/>
                    </ListItem>
                ))}
            </List>
        </Drawer>
    );
};


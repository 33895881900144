import {MegaAppAxiosInstance} from "./axios.instance";
import {Item, PaginatedResponse} from "./items-api";
import {References} from "./reference-api";

export type CraftResult = {
    type: References | 'item' | 'Empty';
    rarity_id?: number;
    title?: string;
    type_value?: number;
    itemId?: number;
    probability: number;
    preview?: string;
    rarity_title?: string;
}

export type RecipesResponse = {
    id: number
    items: string;
    results: string;
    results_json: CraftResult[];
    items_preview: any;
    results_preview: any;
    created_at?: string;
    updated_at?: string;
    items_json: Item[]
}

export type CreateRecipesDto = {
    items: string;
    items_preview: any;
    results: string;
    results_json: CraftResult[];
    results_preview: any;
    is_choice: boolean
}

export const RecipesApi = {

    async getAll(page: number, pageSize: number): Promise<PaginatedResponse<RecipesResponse>> {
        try {
            return MegaAppAxiosInstance.get<PaginatedResponse<RecipesResponse>>('/recipes', {
                params: {
                    page,
                    pageSize,
                }
            }).then((r) => r.data)
        } catch (error) {
            console.log(error)
            throw new Error('Ошибка при выполнении запроса');
        }
    },

    create(dto: CreateRecipesDto) {
        return MegaAppAxiosInstance.post('/recipes', dto).then((r) => r.data)
    },

    update(id: number, dto: CreateRecipesDto) {
        return MegaAppAxiosInstance.put(`/recipes/${id}`, dto).then((r) => r.data)
    },

    delete(id: number) {
        return MegaAppAxiosInstance.delete(`/recipes/${id}`).then((r) => r.data)
    },

    getById(id: number): Promise<RecipesResponse> {
        return MegaAppAxiosInstance.get(`/recipes/${id}`).then((r) => r.data)
    },

    import(url: string) {
        return MegaAppAxiosInstance.patch<boolean>(`/recipes/import`, {url}).then((r) => r.data)
    }

}

